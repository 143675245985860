import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { GeneralService } from '../_services/general.service';

@Component({
  selector: 'app-tool',
  templateUrl: './tool.component.html',
  styleUrls: ['./tool.component.css']
})
export class ToolComponent implements OnInit {

  tools:any;
  filteredTools:any;
  categories:any;
  categoryName:string;
  searchText:string;

  constructor(private gService:GeneralService) { }

  ngOnInit(): void {
    this.gService.toolCategories()
    .subscribe(res => {
      this.categories = res;

      this.categoryName = res[0].name;
      this.gService.tools(res[0].id)
        .subscribe(res => this.tools = this.filteredTools = res);
    });
  }

  categoryChanged(id,name){
    this.gService.tools(id)
      .subscribe(res => this.tools = this.filteredTools = res);
    this.categoryName = name;

  }
 
  filterTools(){
    if(this.searchText != ''){
      this.gService.allTools()
      .subscribe(res => {
        this.filteredTools = res.filter(l => l.name.toLowerCase().includes(this.searchText.toLowerCase()))
      });
    }else{
      this.ngOnInit();
    }
  }
}
