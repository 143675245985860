import { SafePipe } from './_pipes/safe.pipe';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations'
import { ModalModule } from 'ngx-bootstrap/modal';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ServiceComponent } from './service/service.component';
import { IndustryComponent } from './industry/industry.component';
import { NavComponent } from './nav/nav.component';
import { FooterComponent } from './footer/footer.component';
import { QualityAndStandardComponent } from './quality-and-standard/quality-and-standard.component';
import { ToolComponent } from './tool/tool.component';
import { LanguageComponent } from './language/language.component';
import { ContactComponent } from './contact/contact.component';
import { BlogComponent } from './blog/blog.component';
import { MaxLengthPipe } from './_pipes/max-length.pipe';
import { ArticleDetailsComponent } from './blog/article-details/article-details.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    ServiceComponent,
    IndustryComponent,
    NavComponent,
    FooterComponent,
    QualityAndStandardComponent,
    ToolComponent,
    LanguageComponent,
    ContactComponent,
    BlogComponent,
    MaxLengthPipe,
    ArticleDetailsComponent,
    SafePipe
    ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    CarouselModule,
    BrowserAnimationsModule,
    ModalModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
