    <!-- Page Header Start -->
    <div class="page-header">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h2>BLOG</h2>
                </div>
            </div>
        </div>
    </div>
    <!-- Page Header End -->

            <!-- Language Start -->
            <div class="blog">
            <div class="container">
                <div class="row align-items-start">
                    <div *ngFor="let article of (articles$ |async)" 
                        class="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <div class="card">
                            <img [src]="article.image" class="card-img-top" alt="...">
                            <div class="card-body">
                              <h5 class="card-title">{{article.title|titlecase}}</h5>
                              <p class="card-text">
                                  {{article.description |maxLength}}
                                  <a [routerLink]="['/article',article.id]" class="">Read More</a>
                              </p>
                            </div>
                            <div class="card-footer">
                                <button type="button" class="btn btn-primary" (click)="openModal(template,article.id)">
                                   <i class="fa fa-share"></i> SHARE</button>
 
                            </div>
                          </div>
                    </div>
             
                </div>
            </div>
        </div>
            <!-- Language End -->

<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Share Article</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="(article$|async) as article">
    <h2 style="font-size: 15px;font-weight: 700;">{{article.title}}</h2>
    <img [src]="article.image" alt="" width="100%" height="100%">
    <p class="mt-2">{{modalContent}}</p>
  </div>
  <div class="modal-footer">
    <button class="btn btn-success" (click)="copyToClipboard(modalContent)">
        <i class="fa fa-copy"></i> Copy</button>
  </div>
</ng-template>
    