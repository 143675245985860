import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn :'root'
})
export class GeneralService{
    
    constructor(private http:HttpClient) {
    }

    services(){
        return this.http.get<any>(`${environment.BaseUrl}/api/service`);
    }
    subServices(id:number){
        return this.http.get<any>(`${environment.BaseUrl}/api/service/subServices/${id}`);
    }

    about(){
        return this.http.get<any>(`${environment.BaseUrl}/api/aboutUs`);
    }

    articles(){
        return this.http.get<any>(`${environment.BaseUrl}/api/blog`);
    }

    article(id:number){
        return this.http.get<any>(`${environment.BaseUrl}/api/blog/${id}`);
    }

    contactInfo(){
        return this.http.get<any>(`${environment.BaseUrl}/api/contactInfo`);
    }

    newMessage(message:any){
        return this.http.post<any>(`${environment.BaseUrl}/api/contactInfo/newMessage`,message);
    }

    industries(){
        return this.http.get<any>(`${environment.BaseUrl}/api/industry`);
    }

    industry(id:number){
        return this.http.get<any>(`${environment.BaseUrl}/api/industry/${id}`);
    }

    qualityAndStandard(){
        return this.http.get<any>(`${environment.BaseUrl}/api/qualityAndStandard`);
    }

    languages(id:number){
        return this.http.get<any>(`${environment.BaseUrl}/api/language/languages/${id}`);
    }

    allLanguages(){
        return this.http.get<any>(`${environment.BaseUrl}/api/language/Languages/All`);
    }
    
    langCategories(){
        return this.http.get<any>(`${environment.BaseUrl}/api/language/category`);
    }

    
    tools(id:number){
        return this.http.get<any>(`${environment.BaseUrl}/api/tool/tools/${id}`);
    }

    allTools(){
        return this.http.get<any>(`${environment.BaseUrl}/api/tool/tools/All`);
    }
    toolCategories(){
        return this.http.get<any>(`${environment.BaseUrl}/api/tool/category`);
    }
}