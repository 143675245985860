    <!-- Page Header Start -->
    <div class="page-header">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h2>CONTACT US</h2>
                </div>
            </div>
        </div>
    </div>
    <!-- Page Header End -->

            <!-- Language Start -->
            <div class="contact">
            <div class="container">
                <div class="row align-items-start">
                    <div class="col-md-8 col-sm-12 my-form">
                        <h2>CONTACT FORM</h2>

                        <div *ngIf="success" class="alert alert-info">Message Send Successfully</div>
                        <form #form="ngForm" (ngSubmit)="sendMessage(form)">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                  <i class="input-group-text fa fa-user text-center"></i>
                                </div>
                                <input #name="ngModel" name="name" ngModel required type="text" class="form-control" placeholder="Name">
                                <div *ngIf="!name.valid &&name.touched" class="text-danger w-100">
                                   This field is Reuired.
                                  </div> 
                            </div>
                             

                              <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                  <i class="input-group-text fa fa-envelope text-center"></i>
                                </div>
                                <input #email="ngModel" name="email" ngModel required type="text" class="form-control" placeholder="Email">
                                <div *ngIf="!email.valid &&email.touched" class="text-danger w-100">
                                    This field is Reuired.
                                   </div> 
                              </div>
                            
                              <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                  <i class="input-group-text fa fa-phone text-center"></i>
                                </div>
                                <input #phone="ngModel" name="phoneNumber" ngModel required type="text" class="form-control" placeholder="Phone Number">
                                <div *ngIf="!phone.valid &&phone.touched" class="text-danger w-100">
                                    This field is Reuired.
                                   </div> 
                              </div>
                            
                              <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                  <i class="input-group-text fa fa-comments text-center"></i>
                                </div>
                                <textarea #message="ngModel" name="message" ngModel required rows="4" class="form-control" placeholder="Message"></textarea>
                                <div *ngIf="!message.valid &&message.touched" class="text-danger w-100">
                                    This field is Reuired.
                                   </div> 
                              </div>
                            
                            <button class="btn btn-secondary" type="submit">SEND</button>
                        </form>
                    </div>
             
                    <div class="col-md-4 col-sm-12 bg-white p-3 address">
                        <h2>ADDRESS INFO</h2>
                        <div *ngIf="(contactInfos$|async) as info">
                          <div *ngFor="let office of info.offices">
                                  <h4>{{office.name}}</h4>
                                  <p>{{office.address}}</p>
                                  <p>Telephone : {{office.telephone}}</p>
                                  <p>Email : {{info.email}}</p> 
                                  <!-- <p>
                                      Location :
                                      <a target="_blank" style="color:#e72d87" href="https://www.google.com/search?q={{office.location}}">Location</a>   
                                  </p> -->
                                <iframe [src]="linkUrl(office.location) |safe:'resourceUrl'" width="300" height="300" style="border:0;" allowfullscreen=""></iframe>
                          </div>
                        </div>
                </div>
            </div>
        </div>
            <!-- Language End -->
    