    <!-- Page Header Start -->
    <div class="page-header">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h2>About Us</h2>
                </div>
                <!-- <div class="col-12">
                    <a href="">Home</a>
                    <a href="">About Us</a>
                </div> -->
            </div>
        </div>
    </div>
    <!-- Page Header End -->

            <!-- About Start -->
            <div class="about">
                <div class="container">
                    <div class="row align-items-start">
                        <div *ngFor="let about of (abouts$ |async)" class="col-lg-4 col-md-6 col-sm-12">
                            <div class="section-header text-left">
                                <h2>{{about.title |uppercase}}</h2>
                            </div>
                            <div class="about-text">
                                <p>
                                    {{about.description}}
                                </p>   
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- About End -->
    