import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { GeneralService } from '../_services/general.service';

@Component({
  selector: 'app-industry',
  templateUrl: './industry.component.html',
  styleUrls: ['./industry.component.css']
})
export class IndustryComponent implements OnInit {

  industries:any;
  industry$:Observable<any>;
  industryName:string;

  constructor(private gService:GeneralService) { }

  ngOnInit(): void {
    this.gService.industries()
    .subscribe(res => {
      this.industries = res;

      this.industryName = res[0].title;
      this.industry$ = this.gService.industry(res[0].id);
    });
  }

  industryChanged(id,name){
    this.industry$ = this.gService.industry(id);
    this.industryName = name;

  }

}
