import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { GeneralService } from '../_services/general.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  contactInfos$:Observable<any>;
  success:boolean;      
  constructor(private gService:GeneralService) { }

  ngOnInit(): void {
      this.contactInfos$ = this.gService.contactInfo();
  }

  sendMessage(form:NgForm){
    if(!form.valid)
      return false;

      this.gService.newMessage(form.value)
      .subscribe(_ =>{
        form.reset();
        this.success = true;

        setTimeout(()=>{
          this.success = false;
        },2000)
      });
  }

  linkUrl(url:string){
    var matches = url.match(/\/@([\d\.,-]+)z\//)[1];
    var splits = matches.split(',');
    var lat = splits[0];
    var long = splits[1];
    var zoom = splits[2];
    var link = 'http://maps.google.com/maps?q=' + lat + ',' + long + '&z=' + zoom + '&output=embed';
   return link;
  }
  
}
