    <!-- Page Header Start -->
    <div class="page-header">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h2>ARTICLE DETAILS</h2>
                </div>
            </div>
        </div>
    </div>
    <!-- Page Header End -->

            <!-- Language Start -->
            <div class="blog">
            <div class="container">
                <div class="row align-items-start">
                    <div *ngIf="(article$ |async) as article" 
                        class="col-sm-12">
                        <div class="card">
                            <img [src]="article.image" class="card-img-top" alt="...">
                            <div class="card-body">
                              <h5 class="card-title">{{article.title|titlecase}}</h5>
                              <p class="card-text">
                                  {{article.description}}
                              </p>
                            </div>
                          </div>
                    </div>
             
                </div>
            </div>
        </div>
            <!-- Language End -->
    