    <!-- Page Header Start -->
    <div class="page-header">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h2>SERVICES</h2>
                </div>
            </div>
        </div>
    </div>
    <!-- Page Header End -->

            <!-- Language Start -->
            <div class="service">
            <div class="container">
                <div class="row align-items-start">
                    <div class="col-lg-4 col-md-4">
                        <div class="list-group">
                            <button
                            (click)="serviceChanged(service.id,service.title)"
                            *ngFor="let service of services"
                            [ngClass]="service.title === serviceName ? 'active' : ''"
                            type="button" class="list-group-item list-group-item-action" aria-current="true">
                            <img width="40" height="40" style="border-radius: 50%;" [src]="service.icon" alt="">
                             {{service.title |titlecase}}        
                            </button>
                        </div>
                    
                    </div>
                    <div class="col-lg-8 col-md-8 bg-white">
                        <div class="section-header text-left pl-4">
                            <h2 *ngIf="serviceName">{{serviceName|titlecase}}</h2>
                        </div>
                        <div class="quality-text px-5 py-3">
                            <div *ngFor="let subService of (subService$|async)" class="mb-3">
                                <div class="row">
                                    <div class="col-lg-4 col-md-4 col-sm-12">
                                        <h5>{{subService.title}}</h5>
                                    </div>
                                    <div class="col-lg-8 col-md-8 col-sm-12">
                                        <p style="font-size: 15px;">{{subService.description}}</p>
                                    </div>
                                </div>
                                <hr>

                            </div>
                          
                            <!-- <a class="btn" href="">Learn More</a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <!-- Language End -->
    