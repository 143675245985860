    <!-- Page Header Start -->
    <div class="page-header">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h2>INDUSTRIES</h2>
                </div>
            </div>
        </div>
    </div>
    <!-- Page Header End -->

            <!-- Language Start -->
            <div class="industry">
            <div class="container">
                <div class="row align-items-start">
                    <div class="col-lg-4 col-md-4">
                        <div class="list-group">
                            <button style="font-size: 15px;"
                            (click)="industryChanged(industry.id,industry.title)"
                            *ngFor="let industry of industries"
                            [ngClass]="industry.title === industryName ? 'active' : ''"
                            type="button" class="list-group-item list-group-item-action" aria-current="true">
                           <img width="40" height="40" style="border-radius: 50%;" [src]="industry.icon" alt="">
                             {{industry.title |titlecase}}        
                            </button>
                        </div>
                    
                    </div>
                    <div class="col-lg-8 col-md-8 bg-white row ml-2">
                        <div class="d-flex align-items-center">
                        <div class="col-md-8 col-sm-12">
                            <div class="section-header text-left pl-4">
                                <h2 *ngIf="industryName">{{industryName|titlecase}}</h2>
                            </div>
                            <div class="quality-text px-5 py-3">
                                <div *ngIf="(industry$|async) as industry" class="mb-3">
                                    {{industry.description}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-12">
                            <img 
                            style="width: 150px; height: 150px;"
                            *ngIf="(industry$|async) as industry" [src]="industry.image" alt="">
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
            <!-- Language End -->
    