import { GeneralService } from './../_services/general.service';
import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-quality-and-standard',
  templateUrl: './quality-and-standard.component.html',
  styleUrls: ['./quality-and-standard.component.css']
})
export class QualityAndStandardComponent implements OnInit {

  qualities$ :Observable<any>;
  constructor(private gService:GeneralService) { }

  ngOnInit(): void {
    this.qualities$ = this.gService.qualityAndStandard();
  }

}
