    <!-- Hero Start -->
    <div class="hero">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-sm-12 col-md-6">
                    <div class="hero-text">
                        <h1>We'are the Bridge to the Global Audience</h1>
                        <p>
                            Fast and Easy Professional Translation Service!
                            Lets start a project now
                        </p>

                        <div class="hero-btn">
                            <a class="btn" (click)="openModal(contactTemplate)">GET A QUOTE</a>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 d-none d-md-block">
                    <div class="hero-image">
                        <img src="assets/img/header.png" alt="Hero Image">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Hero End -->


    <!-- Service Start -->
    <div class="service">
        <div class="container">
            <div class="section-header text-center wow zoomIn" data-wow-delay="0.1s">
                <h2>OUR SERVICES</h2>
            </div>
            <div class="row">
                <div *ngFor="let service of (services$ |async)" class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.0s">
                    <div class="service-item">
                        <div class="service-icon">
                            <!-- <i class="flaticon-workout"></i> -->
                            <img [src]="service?.icon" width="50" height="50" alt="">
                        </div>
                        <h3>{{service.title |uppercase}}</h3>
                        <a style="background:#e72d87;border-color:#e72d87" routerLink="services" class="btn btn-primary">READ MORE</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Service End -->
    

    <!-- Discount Start -->
    <div class="discount wow zoomIn" data-wow-delay="0.1s">
        <div class="container about-res">
            <div class="section-header text-center">
                <h2>ABOUT US</h2>
            </div>
            <div class="container discount-text">
                <p *ngIf="(aboutUs$ | async) as about">
                    {{about[0]?.description}}
                </p>
                <a routerLink="about" class="btn">MORE ABOUT US</a>
            </div>
        </div>
    </div>
    <!-- Discount End -->

    
    <!-- Industry Start -->
    <div class="industry">
        <div class="container">
            <div class="section-header text-center">
                <h2 style="font-weight: 700;">INDUSTRIES</h2>
            </div>
            <div class="">
                <owl-carousel-o [options] = "options">
                    <ng-template *ngFor="let industry of (industries$ |async)" carouselSlide>
                        <div class="wow fadeInUp" data-wow-delay="0.0s">
                            <div class="industry-item">
                                <div class="industry-icon">
                                    <img [src]="industry?.icon" width="50" height="50" alt="">         
                                </div>
                                <h3>{{industry.title |uppercase}}</h3>
                                <a style="background:#e72d87;border-color:#e72d87" routerLink="industries" class="btn btn-primary">READ MORE</a>
                            </div>
                        </div>
        
                    </ng-template>

                    
                </owl-carousel-o>
            
            </div>
        </div>
    </div>
    <!-- Service End -->


    <ng-template #contactTemplate>
        <div class="my-form">
            <div class="modal-header">
                <h2>CONTACT FORM</h2>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                  </button>
            </div>
        <div class="modal-body">
            <div *ngIf="success" class="alert alert-info">Message Send Successfully</div>
            <form #form="ngForm" (ngSubmit)="sendMessage(form)">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <i class="input-group-text fa fa-user text-center"></i>
                    </div>
                    <input #name="ngModel" name="name" ngModel required type="text" class="form-control" placeholder="Name">
                    <div *ngIf="!name.valid &&name.touched" class="text-danger w-100">
                       This field is Reuired.
                      </div> 
                </div>
                 

                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <i class="input-group-text fa fa-envelope text-center"></i>
                    </div>
                    <input #email="ngModel" name="email" ngModel required type="text" class="form-control" placeholder="Email">
                    <div *ngIf="!email.valid &&email.touched" class="text-danger w-100">
                        This field is Reuired.
                       </div> 
                  </div>
                
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <i class="input-group-text fa fa-phone text-center"></i>
                    </div>
                    <input #phone="ngModel" name="phoneNumber" ngModel required type="text" class="form-control" placeholder="Phone Number">
                    <div *ngIf="!phone.valid &&phone.touched" class="text-danger w-100">
                        This field is Reuired.
                       </div> 
                  </div>
                
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <i class="input-group-text fa fa-comments text-center"></i>
                    </div>
                    <textarea #message="ngModel" name="message" ngModel required rows="4" class="form-control" placeholder="Message"></textarea>
                    <div *ngIf="!message.valid &&message.touched" class="text-danger w-100">
                        This field is Reuired.
                       </div> 
                  </div>
                
                <button class="btn btn-secondary" type="submit">SEND</button>
            </form>
        </div>
        </div>
 
    </ng-template>
    
    