
        <!-- Page Header Start -->
        <div class="page-header">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h2>QUALITY & STANDARD</h2>
                    </div>
                </div>
            </div>
        </div>
        <!-- Page Header End -->

<!-- Quality Start -->
        <div *ngFor="let quality of (qualities$ |async)" class="quality">
            <div class="container">
                <div class="row align-items-start">
                    <div class="col-lg-4 col-md-4">
                        <div class="quality-img">
                            <img [src]="quality.image" alt="Image">
                        </div>
                    </div>
                    <div class="col-lg-8 col-md-8">
                        <div class="section-header text-left">
                            <h2>Our Quality Control process</h2>
                        </div>
                        <div class="quality-text">
                            <p>
                          {{quality.description}}
                            </p>
                          
                            <!-- <a class="btn" href="">Learn More</a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Quality End -->
