    <!-- Page Header Start -->
    <div class="page-header">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h2>TOOLS</h2>
                </div>
            </div>
        </div>
    </div>
    <!-- Page Header End -->

            <!-- Language Start -->
            <div class="tool">
            <div class="container">
                <div class="row align-items-start">
                    <div class="col-lg-4 col-md-4">
                        <div class="list-group">
                            <button
                            (click)="categoryChanged(category.id,category.name)"
                            *ngFor="let category of categories"
                            [ngClass]="category.name === categoryName ? 'active' : ''"
                            type="button" class="list-group-item list-group-item-action" aria-current="true">
                            {{category.name |titlecase}}        
                            </button>
                        </div>
                    
                    </div>
                    <div class="col-lg-8 col-md-8 bg-white">
                        <div class="section-header text-left pl-4">
                            <h2 *ngIf="categoryName">{{categoryName|titlecase}}</h2>
                        </div>
                        <div class="quality-text px-5 py-3 row">
                            <input placeholder="Search by name" type="text" class="form-control mb-2" [(ngModel)]="searchText" (input)="filterTools()">      
                            
                            <div *ngFor="let tool of filteredTools" class="col-md-4 mb-3">
                                {{tool.name}}
                            </div>
                          
                            <!-- <a class="btn" href="">Learn More</a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <!-- Language End -->
    