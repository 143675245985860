import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { GeneralService } from '../_services/general.service';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.css']
})
export class ServiceComponent implements OnInit {

  services:any;
  subService$:Observable<any>;
  serviceName :string;
  constructor(private gService:GeneralService) { }

  ngOnInit(): void {
    this.gService.services()
    .subscribe(res => {
      this.services = res;
      this.serviceName = res[0].title;
      this.subService$ = this.gService.subServices(res[0].id);
    });
  }

  serviceChanged(id,name){
    this.subService$ = this.gService.subServices(id);
    this.serviceName = name;

  }


}
