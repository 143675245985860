<!-- Nav Bar Start -->
    <div class="navbar navbar-expand-lg"> <!--bg-dark navbar-dark-->
        <div class="container-fluid">
            <a routerLink="/" class="navbar-brand">
                <!-- N<span>W</span>T -->
                <div class="bg-image"></div>
                <!-- <img src="../../assets/img/site-icon.jpeg" style="background-color: transparent;"> -->
            </a>
            <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                <!-- <span class="navbar-toggler-icon"></span> -->
                <span class="fa fa-bars"></span>
            </button>

            <div class="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                <div class="navbar-nav ml-auto">
                    <a  routerLink="/" routerLinkActive ="active" [routerLinkActiveOptions]="{ exact: true }"
                     class="nav-item nav-link">Home</a>
                     <div class="nav-item dropdown">
                        <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown">COMPANY</a>
                        <div class="dropdown-menu">
                            <a
                            routerLink="/about" routerLinkActive ="active" 
                             class="dropdown-item">ABOUT US</a>
                            <a
                            routerLink="/qualityAndStandard" routerLinkActive ="active" 
                             class="dropdown-item">QUALITY & STANDARD</a>
                        </div>
                    </div>
                    <div class="nav-item dropdown">
                        <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown">EXPERTISE</a>
                        <div class="dropdown-menu">
                            <a
                            routerLink="/language" routerLinkActive ="active" 
                             class="dropdown-item">LANGUAGES</a>
                            <a
                            routerLink="/tools" routerLinkActive ="active" 
                             class="dropdown-item">TOOLS</a>
                             <a
                             routerLink="/industries" routerLinkActive ="active" 
                              class="dropdown-item">INDUSTRIES</a>
                        </div>
                    </div>
                    <a routerLink="/services" routerLinkActive ="active"
                    class="nav-item nav-link">SERVICES</a>

                    <a routerLink="/contact" routerLinkActive ="active" 
                    class="nav-item nav-link">CONTACT US</a>
                    <a routerLink="/blog" routerLinkActive ="active" 
                    class="nav-item nav-link">BLOG</a>
                    
                </div>
            </div>
        </div>
    </div>
<!-- Nav Bar End -->


